.svgTriangulobottonBg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 103%;
  background: var(--secondary-color);
  box-sizing: border-box;
  clip-path: polygon(0 0, 100% 0, 100% 74%, 87% 100%, 0 100%);
}

.svgBoxRectanguloBottonRigth::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 104%;
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  clip-path: polygon(0 0, 100% 0, 100% 74%, 87% 100%, 0 100%);

}

.svgTriangulotopBG::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--overlay-video-content);
  box-sizing: border-box;
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
}

.svgTriangulotopBgWhite::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #ffffffb1;
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
}

.svgTriangulotop {
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
}

.svgTriangulobotton {
  clip-path: polygon(50% 100%, 100% 80%, 100% 0, 0 0, 0 80%);
}

.svgParrallelogra {
  clip-path: polygon(0 26%, 100% 0, 100% 100%, 0 81%);
}

.svgParrallelograBg {
  clip-path: polygon(0 0, 100% 0%, 78% 99%, 0% 100%);
}

.svgParrallelograInverso {
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.svgHexagon {
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 50% 100%, 0 100%, 0 20%);

}

.svgHexagonInverso {
  clip-path: polygon(50% 0, 100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
}

.svgTrapezidBg {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

}

.svgParrallelograHorizontal {
  clip-path: polygon(0 20%, 100% 0, 100% 70%, 0 100%);
}

.svgCuadroBG {
  clip-path: polygon(100% 18%, 100% 90%, 89% 100%, 0 100%, 0 18%);
}

.svgServicesImgIzq {
  clip-path: polygon(0 0, 100% 12%, 100% 86%, 0% 100%);
}

.svgServicesImgDer {
  clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 86%);
}

.svgHeader1Left {
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
}

.svgHeader1Right {
  clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
}

.svgHeader1Info {
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
}

.svgHeader1Img {
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
}

.svgHeader1block {
  clip-path: polygon(0 0, 100% 0%, 65% 100%, 0% 100%);
}

.svgTranspareHeader2 {
  clip-path: polygon(0 0, 100% 0, 100% 74%, 87% 100%, 0 100%);
}

.svgHerosection1 {
  position: relative;
  clip-path: polygon(50% 100%, 100% 80%, 100% 0, 0 0, 0 80%);
}

.svgHerosection1::after {
  content: '';
  position: absolute;
  clip-path: polygon(50% 100%, 100% 80%, 100% 0, 0 0, 0 80%);
}

.svgHerosection2 {
  clip-path: polygon(0 0, 100% 0, 100% 74%, 87% 100%, 0 100%);
}

.svgHerosection2::after {
  content: '';
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 100% 74%, 87% 100%, 0 100%);
}

.svgFooter2{
  clip-path: polygon(10% 0, 90% 0, 100% 14%, 100% 100%, 0 100%, 0 14%);
}

.svgMap2{
  clip-path: polygon(0 0, 100% 0, 100% 95%, 90% 100%, 10% 100%, 0 95%);
}

@media screen and (max-width: 560px) {
  .svgHerosection1,
  .svgHerosection1::after {
    clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
  }

  .svgHerosection2,
  .svgHerosection2::after {
    clip-path: polygon(0 0, 100% 0, 100% 84%, 70% 100%, 0 100%);
  }

  .svgHeader1Img {
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }

  .svgHeader1Info {
    clip-path: polygon(0 0, 100% 0%, 89% 100%, 0% 100%);
  }

  .svgHeader1Right {
    clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0 100%);
  }

  .svgHeader1Left {
    clip-path: polygon(0 0, 100% 0%, 50% 100%, 0% 100%);
  }

  .svgBoxRectanguloBottonRigth::before {
    height: 155vh;
    clip-path: polygon(0 0, 100% 0, 100% 84%, 70% 100%, 0 100%);
  }

  .svgTriangulobottonBg::before {
    height: 125vh;
    clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
  }

  .svgTriangulotop,
  .svgTriangulotopBG::before,
  .svgTriangulotopBgWhite::before {
    clip-path: polygon(50% 0%, 100% 5%, 100% 100%, 0 100%, 0 5%);
  }

  .svgParrallelograInverso {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .svgTriangulobotton {
    clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
  }

  .svgCuadroBG {
    clip-path: polygon(100% 0, 100% 90%, 89% 100%, 0 100%, 0 0);

  }
}